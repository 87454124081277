import React, { useCallback, useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { NavigationContext } from 'contexts/NavigationContext';

const Element = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: transparent !important;
  pointer-events: none;
`;

export const ScrollOverlay = ({ id, anchorText }) => {
  const element = useInView();

  const { initialSearchParam } = useContext(NavigationContext);

  const handleURL = useCallback(() => {
    const SECTION_ELEMENT = document.querySelector(`#${id}`);

    const sectionHeight = SECTION_ELEMENT?.getBoundingClientRect().height;
    const sectionTop = SECTION_ELEMENT?.getBoundingClientRect().top;

    if (sectionTop < 1 && sectionTop > -sectionHeight) {
      window.history.replaceState(
        null,
        '',
        `${initialSearchParam}#${anchorText}`
      );
    }
  }, [initialSearchParam]);

  useEffect(() => {
    if (element.inView) {
      window.addEventListener('scroll', handleURL);
    } else {
      window.removeEventListener('scroll', handleURL);
    }
  }, [element.inView, initialSearchParam]);

  return <Element id={id} ref={element.ref} />;
};
