import React from 'react';
import styled from 'styled-components';
import Icon from 'components/shared/Icon';
import Content from 'components/shared/Content';
import matexiLogo from 'assets/icons/matexi-logo-15-lecie.svg';
import igIcon from 'assets/icons/instagram.svg';
import fbIcon from 'assets/icons/facebook.svg';
import linkedinIcon from 'assets/icons/linkedin.svg';
import ytIcon from 'assets/icons/youtube.svg';
import twitterIcon from 'assets/icons/twitter.svg';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';
import { Link } from 'gatsby';

const Wrapper = styled.footer`
  color: ${({ theme }) => theme.colors.green};
  background: ${({ theme }) => theme.colors.white};
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  span {
    display: none;
    margin-right: 10px;
    font-size: 2.1rem;
    font-weight: ${({ theme }) => theme.light};
    ${({ theme }) => theme.mq.xs} {
      display: block;
    }
    ${({ theme }) => theme.mq.s} {
      font-size: 2.4rem;
    }
  }
`;

const Logo = styled.a`
  order: -1;
  width: 210px;
  margin-bottom: 35px;
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 0;
    order: 1;
  }
`;

const SocialMediaItem = styled.a`
  display: block;
  margin: 0 5px;
`;

const PoliciesLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 12px;

  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    position: absolute;
    top: 50%;
    left: 56%;
    transform: translate(-50%, -50%);
    margin-top: 0;
  }

  ${({ theme }) => theme.mq.lg} {
    left: calc(50% + 50px);
    gap: 24px;
  }
`;

const PrivacyPolicyLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green};
  text-decoration: none;

  ${({ theme }) => theme.mq.md} {
    font-size: 16px;
  }
`;

const getIcon = name => {
  switch (name.toLowerCase()) {
    case 'instagram':
      return igIcon;
    case 'facebook':
      return fbIcon;
    case 'linkedin':
      return linkedinIcon;
    case 'youtube':
      return ytIcon;
    case 'twitter':
      return twitterIcon;
    default:
      return null;
  }
};

const Footer = () => {
  const socialMedia = [
    {
      icon: getIcon('instagram'),
      alt: 'instagram',
      link: 'https://www.instagram.com/matexi_polska/',
    },
    {
      icon: getIcon('facebook'),
      alt: 'facebook',
      link: 'https://www.facebook.com/witamywokolicy',
    },
    {
      icon: getIcon('linkedin'),
      alt: 'linkedin',
      link: 'https://www.linkedin.com/company/matexipolska/?originalSubdomain=pl',
    },
    {
      icon: getIcon('youtube'),
      alt: 'youtube',
      link: 'https://www.youtube.com/channel/UCPBcsi_oUEhcSYsgWoLpRjg/videos?sort=da&flow=grid&view=0&app=desktop',
    },
  ];

  return (
    <Wrapper>
      <Content>
        <InnerWrapper>
          <SocialMedia>
            <span>Znajdź nas:</span>
            {socialMedia.map(({ icon, alt, link }) => (
              <SocialMediaItem
                target="_blank"
                href={link}
                key={alt}
                onClick={() =>
                  emitMatomoEvent({
                    event: eventsNames.click_social_media,
                    nazwa: alt,
                  })
                }
              >
                <Icon size={42} src={icon} alt={alt} />
              </SocialMediaItem>
            ))}
          </SocialMedia>
          <PoliciesLinks>
            <PrivacyPolicyLink to="/polityka-prywatnosci">
              Polityka prywatności
            </PrivacyPolicyLink>
            <PrivacyPolicyLink to="/polityka-cookies">
              Polityka cookies
            </PrivacyPolicyLink>
          </PoliciesLinks>
          <Logo
            href="https://matexipolska.pl/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <Icon full src={matexiLogo} alt="logo matexi" />
          </Logo>
        </InnerWrapper>
      </Content>
    </Wrapper>
  );
};

export default Footer;
