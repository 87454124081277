import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
// import regulaminIcon from 'assets/icons/regulamin.svg';
import pdfIcon from 'assets/icons/pdf2.svg';

import useMedia from 'hooks/useMedia';

const StyledSection = styled(Section)`
  position: relative;

  ${({ theme }) => theme.mq.lg} {
    padding-top: 120px;
  }
`;

const Heading = styled.h2`
  font-size: 2.3rem;
  line-height: normal;
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 15px;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.6rem;
  }

  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 30px;
  }
`;

const LogoWrapper = styled.div`
  width: 140px;
  margin: 5px 0 25px;

  ${({ theme }) => theme.mq.lg} {
    width: 160px;
  }
`;

const Description = styled.p`
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.white};
  line-height: 2.2rem;
  margin-bottom: 25px;
`;

const Description2 = styled(Description)`
  margin-bottom: 0;
`;

const Wrapper = styled.article`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 15px;

  ${({ theme }) => theme.mq.lg} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.mq.s} {
    min-height: 410px;
    min-width: 500px;
  }
  ${({ theme }) => theme.mq.xl} {
    max-height: 440px;
  }
`;

const GreenColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.green};
  padding: 20px 15px;

  ${({ theme }) => theme.mq.md} {
    padding: 30px 25px;
    align-items: center;
    justify-content: center;
  }

  ${({ theme }) => theme.mq.lg} {
    padding: 30px;
  }

  ${({ theme }) => theme.mq.xl} {
    padding: 40px;
  }
`;

const ColumnContent = styled.div``;

const Pdfs = styled.div`
  display: grid;
  row-gap: 20px;
  margin-bottom: 30px;
`;

const StyledDetail = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  max-width: max-content;
  margin-left: -4px;
`;

const DetailsLabels = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  span {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
    transition: 0.3s;
    &:hover {
      color: ${({ theme }) => theme.colors.lightGreen200};
    }
  }
`;

const ButtonLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 48px;
  margin: 20px 0;
  padding: 0 20px;
  background: ${({ theme }) => theme.colors.lightGreen300};
  text-transform: none;
  letter-spacing: 0;
  font-size: 1.4rem;
  font-weight: 600;
  box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  &:hover {
    background: ${({ theme }) => theme.colors.lightGreen400};
  }
`;

// const Link = styled.a`
//   display: flex;
//   align-items: center;
//   color: ${({ theme }) => theme.colors.white};
//   transition: 0.3s;
//   text-decoration: none;
//   max-width: max-content;
//   span {
//     transition: 0.3s;
//     margin-left: 8px;
//     color: ${({ theme }) => theme.colors.white};

//     &:hover {
//       color: ${({ theme }) => theme.colors.lightGreen200};
//     }
//   }
//   &:hover {
//     color: ${({ theme }) => theme.colors.lightGreen200};
//   }
// `;

const ReadyPickup = ({
  heading,
  description,
  text1,
  text2,
  image,
  logo,
  pdfs,
  descriptionPdfs,
  // fileLink,
  phone,
}) => {
  const matchesLG = useMedia('(min-width: 992px)');

  const plusSignIndex = phone.indexOf('+');
  const phoneNumber = phone.substring(plusSignIndex);

  return (
    <StyledSection>
      <Content>
        <Heading dangerouslySetInnerHTML={{ __html: heading }} />
        <LogoWrapper>
          <LazyImage src={logo} alt="logo" />
        </LogoWrapper>
        {/* <Description dangerouslySetInnerHTML={{ __html: description }} /> */}
        <Wrapper>
          <GreenColumn>
            <ColumnContent>
              <Description
                dangerouslySetInnerHTML={{ __html: descriptionPdfs }}
              />
              <Description2 dangerouslySetInnerHTML={{ __html: text1 }} />

              {/* <Pdfs>
                  {pdfs.map(pdf => (
                    <StyledDetail key={pdf.name}>
                      <Icon
                        src={pdfIcon}
                        alt="pdf icon"
                        size={matchesLG ? 48 : 32}
                      />
                      <DetailsLabels>
                        <span>{pdf.name}</span>
                        <a
                          target="_blank"
                          href={pdf.link}
                          rel="noopener noreferrer nofollow"
                        >
                          {pdf.text}
                        </a>
                      </DetailsLabels>
                    </StyledDetail>
                  ))}
                </Pdfs> */}
              <ButtonLink as="a" href={`tel: ${phoneNumber}`}>
                ZADZWOŃ {phone}
              </ButtonLink>
              <Description2 dangerouslySetInnerHTML={{ __html: text2 }} />
              {/* <Link target="_blank" href={fileLink}>
                  <Icon
                    src={regulaminIcon}
                    alt="regulations icon"
                    size={matchesLG ? 36 : 30}
                  />
                  <span>Regulamin AMBASADOR Deer Design</span>
                </Link> */}
            </ColumnContent>
          </GreenColumn>
          <ImageWrapper>
            <LazyImage src={image} alt="image" cover />
          </ImageWrapper>
        </Wrapper>
      </Content>
    </StyledSection>
  );
};

ReadyPickup.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  descriptionPdfs: PropTypes.string.isRequired,
  pdfs: PropTypes.arrayOf(PropTypes.object).isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  logo: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  // fileLink: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default ReadyPickup;
