import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyImage from 'components/shared/LazyImage';
import Content from 'components/shared/Content';
import Section from 'components/shared/Section';
import Icon from 'components/shared/Icon';
import Map from 'components/Localization/Map';
import LazyLoad from 'react-lazyload';
import useMedia from 'hooks/useMedia';
import scienceIcon from 'assets/icons/Nauka.svg';
import sportIcon from 'assets/icons/Sport.svg';
import transportIcon from 'assets/icons/Transport.svg';
import restaurantsIcon from 'assets/icons/Restauracje.svg';
import shopsIcon from 'assets/icons/Sklep.svg';
import Zoom from 'react-medium-image-zoom';

const Wrapper = styled.div`
  padding: 20px 0 0;
  ${({ theme }) => theme.mq.xs} {
    padding: 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
`;

const StyledSection = styled(Section)`
  padding-bottom: 0 !important;

  ${({ theme }) => theme.mq.lg} {
    padding-top: 0;
  }
`;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  ${({ theme }) => theme.mq.xs} {
    height: 400px;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 100%;
    height: 800px;
  }
`;

const Options = styled.div`
  position: absolute;
  top: 55%;
  left: 0;
  transform: translateY(-55%);
  border-radius: 0 20px 20px 0;
  width: 70px;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.green};
  border-left: none;
`;

const MarkerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: 52px;
  height: 52px;
  margin: 8px 0;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
  opacity: ${({ visible }) => (visible ? '1' : '0.3')};
  &:hover {
    opacity: 1;
  }
`;

const Topbar = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 20px 20px;
  height: 60px;
  background: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.colors.green};
  border-top: none;
  padding: 20px 30px;
`;

const Text = styled.h2`
  font-size: 2.2rem;
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.green};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
  }
`;

const Column = styled.div`
  background: ${({ theme }) => theme.colors.green};
  width: 100%;
  padding: 30px 10px 0;
  ${({ theme }) => theme.mq.md} {
    width: 50%;
  }
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

const Heading = styled.h2`
  font-size: 3rem;
  margin-bottom: 10px;
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  min-width: 200px;
`;

const GridGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin: 20px 0 0 0;
  @media (max-width: 360px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    @supports not (gap: 25px) {
      & > div:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }
  ${({ theme }) => theme.mq.md} {
    width: 50%;
    margin: 0 0 0 20px;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 40px 0 30px 20px;
    margin: 0;
  }
`;

const GalleryImage = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  height: 100%;
  min-height: 200px;
  @media (max-width: 360px) {
    height: 220px;
  }
`;

const Label = styled.div`
  position: absolute;
  right: 0;
  bottom: -10px;
  padding: 10px;
  text-align: center;
  background: ${({ theme }) => theme.colors.lightGreen100};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.3rem;
`;

const Localization = ({ image, imageAlt, content, locations, gallery }) => {
  const allLocations = locations.map(location => ({
    ...location,
    locMapLocationName: location.homepageLocationMapPlace,
    isMarkerShown: true,
  }));

  const matchesLG = useMedia('(min-width: 992px)');

  const [activeLocations, setActiveLocations] = useState(allLocations);
  const [currentLocation, setCurrentLocation] = useState('');
  const [visibleTypes, setVisibleMarkers] = useState([
    'school',
    // 'park',
    'sport',
    'transport: transport',
    'restaurant',
    'shops',
  ]);

  const handleBtnClick = option => {
    if (visibleTypes.includes(option)) {
      setVisibleMarkers(visibleTypes.filter(item => item !== option));
    } else setVisibleMarkers([...visibleTypes, option]);
  };

  useEffect(() => {
    const newLocations = allLocations.filter(({ homepageLocationMapPlace }) =>
      visibleTypes.includes(homepageLocationMapPlace)
    );

    setActiveLocations(newLocations);
  }, [visibleTypes]);

  const investmentLocation = locations
    .filter(
      ({ homepageLocationMapPlace }) =>
        homepageLocationMapPlace === 'investment'
    )
    .map(location => ({
      ...location,
      locMapLocationName: location.homepageLocationMapPlace,
    }));

  return (
    <StyledSection>
      <Wrapper>
        <InnerWrapper>
          {/* <StyledContent> */}
          <ContentWrapper>
            <Column>
              <Content>
                <Heading>Lokalizacja</Heading>
                <p dangerouslySetInnerHTML={{ __html: content }} />
              </Content>
              <ImageWrapper>
                <LazyImage src={image} alt={imageAlt} cover />
              </ImageWrapper>
            </Column>
            <GridGallery>
              {gallery.map(({ src, text }) => (
                <GalleryImage key={text}>
                  <Zoom
                    wrapStyle={{
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <LazyImage src={src} alt={text} cover />
                  </Zoom>
                  <Label>{text}</Label>
                </GalleryImage>
              ))}
            </GridGallery>
          </ContentWrapper>
          {/* </StyledContent> */}
          <LazyLoad height={800}>
            <MapWrapper>
              <Map
                setCurrentLocation={setCurrentLocation}
                locations={[...activeLocations, ...investmentLocation]}
                lat={Number(investmentLocation[0].homepageLocationMapLat)}
                lng={Number(investmentLocation[0].homepageLocationMapLng)}
              />
              {matchesLG && locations.length > 0 && (
                <>
                  <Options>
                    <MarkerButton
                      onClick={() => handleBtnClick('school')}
                      visible={visibleTypes.includes('school')}
                    >
                      <Icon src={scienceIcon} alt="nauka" full />
                    </MarkerButton>

                    <MarkerButton
                      onClick={() => handleBtnClick('sport')}
                      visible={visibleTypes.includes('sport')}
                    >
                      <Icon src={sportIcon} alt="sport" full />
                    </MarkerButton>
                    <MarkerButton
                      onClick={() => handleBtnClick('transport: transport')}
                      visible={visibleTypes.includes('transport: transport')}
                    >
                      <Icon src={transportIcon} alt="transport" full />
                    </MarkerButton>
                    <MarkerButton
                      onClick={() => handleBtnClick('restaurant')}
                      visible={visibleTypes.includes('restaurant')}
                    >
                      <Icon src={restaurantsIcon} alt="restauracje" full />
                    </MarkerButton>
                    <MarkerButton
                      onClick={() => handleBtnClick('shops')}
                      visible={visibleTypes.includes('shops')}
                    >
                      <Icon src={shopsIcon} alt="sklepy" full />
                    </MarkerButton>
                  </Options>
                  {currentLocation && (
                    <Topbar>
                      <Text>{currentLocation}</Text>
                    </Topbar>
                  )}
                </>
              )}
            </MapWrapper>
          </LazyLoad>
        </InnerWrapper>
      </Wrapper>
    </StyledSection>
  );
};

Localization.propTypes = {
  content: PropTypes.string.isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  gallery: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Localization;
