import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/shared/SEO';
import Popup from 'components/shared/Popup';
import Header from 'components/Header/Header';
import AskAboutFlat from 'components/AskAboutFlat/AskAboutFlat';
import About from 'components/About/About';
import Details from 'components/About/About2';
import ReadyPickup from 'components/ReadyPickup/ReadyPickup';
import Flats from 'components/Flats/Flats';
import Localization from 'components/Localization/Localization';
import Contact from 'components/Contact/Contact';
import Credit from 'components/Credit/Credit';
import ConstructionProgressGallery from 'components/ConstructionProgressGallery/ConstructionProgressGallery';
import Gallery from 'components/Gallery/Gallery';
import manIcon from 'assets/icons/man.svg';
import womanIcon from 'assets/icons/woman.svg';
import Footer from 'components/Footer/Footer';
// import Cookies from 'components/Cookies/Cookies';
import LazyLoad from 'react-lazyload';
import { ButtonPromotion } from 'components/ButtonPromotion';
import { ClientOnly } from 'components/shared/ClientOnly';
import { ScrollOverlay } from 'components/shared/ScrollOverlay';

import { getFlatsFromAPI, parseFlat } from 'utils/getFlatsFromApi';

const Wrapper = styled.div`
  position: relative;
`;

const isSSR = typeof window === 'undefined';

const LazyPromotionDrawer = React.lazy(() =>
  import('components/PromotionDrawer')
);

const IndexPage = ({ data }) => {
  const HOMEPAGE = data.wpPage.homepage;
  const popupImg =
    HOMEPAGE.homepageHeroPopup && HOMEPAGE.homepageHeroPopup.sourceUrl;

  const TIMELINE =
    HOMEPAGE?.homepageAboutInvestmentTimeline?.map(item => ({
      title: item?.homepageAboutInvestmentTimelineTitle,
      active: item?.homepageAboutInvestmentTimelineActive,
      signature: item?.homepageAboutInvestmentTimelineDate,
    })) ?? [];

  const creditTeam = useMemo(
    () =>
      HOMEPAGE.homepageCreditsAdvisors?.map(
        ({
          homepageCreditsAdvisorsEmail,
          plec,
          homepageCreditsAdvisorsName,
          homepageCreditsAdvisorsPhoneNumber,
          homepageCreditsAdvisorsPostion,
        }) => ({
          icon: plec === 'man' ? manIcon : womanIcon,
          name: homepageCreditsAdvisorsName,
          job: homepageCreditsAdvisorsPostion,
          phone: homepageCreditsAdvisorsPhoneNumber,
          email: homepageCreditsAdvisorsEmail,
        })
      ) ?? [],
    []
  );

  const galleryAlbums = useMemo(
    () =>
      HOMEPAGE.homepageGalleryCarousel.map(
        ({ homepageGalleryCarouselTitle, homepageGalleryCarouselImgs }) => ({
          title: homepageGalleryCarouselTitle,
          images: homepageGalleryCarouselImgs.map(
            ({ homepageGalleryCarouselImgsSingle }) => ({
              src: homepageGalleryCarouselImgsSingle.localFile.childImageSharp
                .fluid,
              alt: `${homepageGalleryCarouselImgsSingle.title}`,
            })
          ),
        })
      ),
    []
  );

  const detailsContacts = [
    {
      name: HOMEPAGE.homepageFinishingExcellent.homepageFinishingExcellentName,
      phone:
        HOMEPAGE.homepageFinishingExcellent
          .homepageFinishingExcellentPhoneNumber,
      email:
        HOMEPAGE.homepageFinishingExcellent.homepageFinishingExcellentEMail,
    },
  ];

  const creditOpeningHours = HOMEPAGE?.homepageCreditsOpeningHours?.map(
    item => ({
      from: item?.homepageCreditsOpeningHoursOpened,
      to: item?.homepageCreditsOpeningHoursClosed,
      day: item?.homepageCreditsOpeningHoursDay,
    })
  );

  const openingHoursGrouped =
    creditOpeningHours?.reduce((acc, curr) => {
      const { from, to, day } = curr;

      const existing = acc[from];

      if (existing) {
        existing.push({ from, to, day });
      } else {
        acc[from] = [{ from, to, day }];
      }

      return acc;
    }, {}) ?? [];

  const CREDIT_OPENING_HOURS =
    openingHoursGrouped &&
    Object.values(openingHoursGrouped).map(item => {
      let dayLabel = '';

      item.forEach((it, index) => {
        const isFirst = index === 0;
        const isLast = index === item.length - 1;

        if (isFirst) {
          dayLabel = it.day;
        }

        if (!isFirst) {
          dayLabel = `${dayLabel}, ${it.day}`;
        }

        if (isLast) {
          dayLabel = `${dayLabel}: godz. ${it.from} - ${it.to}`;
        }
      });

      return dayLabel;
    });

  const readyPickupPdfs =
    HOMEPAGE.homepageFinishingDeerDesign.homepageFinishingDeerDesignPackage.map(
      ({
        homepageFinishingDeerDesignPackageName,
        homepageFinishingDeerDesignPackageText,
        homepageFinishingDeerDesignPackageFile,
      }) => ({
        name: homepageFinishingDeerDesignPackageName,
        text: homepageFinishingDeerDesignPackageText,
        link: homepageFinishingDeerDesignPackageFile.link,
      })
    );

  const progressGallery = useMemo(
    () =>
      HOMEPAGE.homepageFinishedStagesGallery.map(
        ({ homepageFinishedStagesGalleryImg }) => ({
          img: homepageFinishedStagesGalleryImg.localFile.childImageSharp.fluid,
          alt: homepageFinishedStagesGalleryImg.altText,
        })
      ),
    []
  );

  const galleryImages = useMemo(
    () =>
      HOMEPAGE.homepageLocationPlaces.map(
        ({ homepageLocationPlacesText, homepageLocationPlacesImg }) => ({
          src: homepageLocationPlacesImg.localFile.childImageSharp.fluid,
          text: homepageLocationPlacesText,
        })
      ),
    []
  );

  const openingHours = useMemo(
    () =>
      HOMEPAGE.homepageContactOpeningHours.map(
        ({
          homepageContactOpeningHoursDay,
          homepageContactOpeningHoursOpened,
          homepageContactOpeningHoursClosed,
        }) => ({
          day: homepageContactOpeningHoursDay,
          openingTime: homepageContactOpeningHoursOpened,
          closingTime: homepageContactOpeningHoursClosed,
        })
      ),
    []
  );

  const [promoFlats, setPromoFlats] = React.useState([]);

  React.useEffect(() => {
    const getFlats = getFlatsFromAPI(
      'https://api.matexipolska.pl/export/api_getproducts/?key=585ee263d5148ce97aa833f48724be1f&mode=json&ExtraData=1&ShowAll=1&ID_ProductKind=1&ID_Investment=46',
      parseFlat
    );
    getFlats()
      .then(flats => {
        const filteredFlats = flats.filter(
          flat => flat.status !== 3 && flat.isPromotion
        );
        setPromoFlats(filteredFlats);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    typeof window !== 'undefined' && (
      <>
        {popupImg && <Popup popupImg={popupImg} />}
        {!isSSR && promoFlats.length > 0 && (
          <>
            <ButtonPromotion count={promoFlats.length} />
            <React.Suspense fallback={<></>}>
              <LazyPromotionDrawer promotionFlats={promoFlats} />
            </React.Suspense>
          </>
        )}
        <SEO />
        <Wrapper>
          <ClientOnly>
            <ScrollOverlay id="o-inwestycji" anchorText="o-inwestycji" />
          </ClientOnly>
          <Header
            background={
              HOMEPAGE.homepageHeroImg.localFile.childImageSharp.fluid
            }
            backgroundAlt="Kameralny Prokocim investment"
            image={HOMEPAGE.homepageHeroIlu.localFile.childImageSharp.fluid}
            content1={HOMEPAGE.homepageHeroText}
            content2={HOMEPAGE.homepageHeroDescription}
            timeline={TIMELINE}
          />
          <AskAboutFlat />
          <About
            content={HOMEPAGE.homepageAboutInvestmentDescription}
            prospect={{
              title:
                HOMEPAGE.homepageAboutInvestmentProspect
                  .homepageAboutInvestmentProspectTitle,
              buttonText:
                HOMEPAGE.homepageAboutInvestmentProspect
                  .homepageAboutInvestmentProspectButtonText,
              link: HOMEPAGE.homepageAboutInvestmentProspect
                .homepageAboutInvestmentProspectFile
                ? HOMEPAGE.homepageAboutInvestmentProspect
                    .homepageAboutInvestmentProspectFile.link
                : null,
            }}
            image={
              HOMEPAGE.homepageAboutInvestmentGraphic.localFile.childImageSharp
                .fluid
            }
          />
        </Wrapper>
        <main>
          <Wrapper>
            <ClientOnly>
              <ScrollOverlay id="lokalizacja" anchorText="lokalizacja" />
            </ClientOnly>
            <Localization
              locations={HOMEPAGE.homepageLocationMap}
              image={
                HOMEPAGE.homepageLocationIlu.localFile.childImageSharp.fluid
              }
              gallery={galleryImages}
              imageAlt="woman and dog"
              content={HOMEPAGE.homepageLocationDescription}
            />
          </Wrapper>
          <Wrapper>
            <ClientOnly>
              <ScrollOverlay id="mieszkania" anchorText="mieszkania" />
            </ClientOnly>
            {HOMEPAGE.homepage3dSearchEngineVisibility && (
              <Flats
                image={
                  HOMEPAGE.homepage3dSearchEngineIlu.localFile.childImageSharp
                    .fluid
                }
                imageBottom={
                  HOMEPAGE.homepage3dSearchEngineWiz.localFile.childImageSharp
                    .fluid
                }
                imageAlt="couple with a box"
                heading="Wybierz najlepsze <br/> mieszkanie dla siebie."
                description={HOMEPAGE.homepage3dSearchEngineText}
              />
            )}
          </Wrapper>

          <Wrapper>
            <ClientOnly>
              <ScrollOverlay id="kredyt" anchorText="kredyt" />
            </ClientOnly>
            <Credit
              heading={HOMEPAGE.homepageCreditsTitle}
              description={HOMEPAGE.homepageCreditsDescription}
              teamHeading={HOMEPAGE.homepageCreditsContactDescription}
              // image={HOMEPAGE.creditImage.localFile.childImageSharp.fluid}
              team={creditTeam}
              text1={HOMEPAGE.homepageCreditsText}
              text2={HOMEPAGE.homepageCreditsAddendum}
              openingHoursLabels={CREDIT_OPENING_HOURS}
            />
          </Wrapper>
          <Wrapper>
            <ClientOnly>
              <ScrollOverlay id="wykonczenia" anchorText="wykonczenia" />
            </ClientOnly>
            <ReadyPickup
              heading={HOMEPAGE.homepageFinishingTitle}
              description={HOMEPAGE.homepageFinishingDescription}
              text1={
                HOMEPAGE.homepageFinishingDeerDesign
                  .homepageFinishingDeerDesignText
              }
              text2={
                HOMEPAGE.homepageFinishingDeerDesign
                  .homepageFinishingDeerDesignText2
              }
              descriptionPdfs={
                HOMEPAGE.homepageFinishingDeerDesign
                  .homepageFinishingDeerDesignDescription
              }
              pdfs={readyPickupPdfs}
              image={
                HOMEPAGE.homepageFinishingDeerDesign
                  .homepageFinishingDeerDesignImg.localFile.childImageSharp
                  .fluid
              }
              logo={
                HOMEPAGE.homepageFinishingDeerDesign
                  .homepageFinishingDeerDesignLogo.localFile.childImageSharp
                  .fluid
              }
              phone={
                HOMEPAGE.homepageFinishingDeerDesign
                  .homepageFinishingDeerDesignPhoneNumber
              }
            />
            <Details
              image={
                HOMEPAGE.homepageFinishingExcellent
                  .homepageFinishingExcellentImg.localFile.childImageSharp.fluid
              }
              description={
                HOMEPAGE.homepageFinishingExcellent
                  .homepageFinishingExcellentDescription
              }
              contactHeading={
                HOMEPAGE.homepageFinishingExcellent
                  .homepageFinishingExcellentText
              }
              fileLink={
                HOMEPAGE.homepageFinishingExcellent
                  .homepageFinishingExcellentFile?.link
              }
              contactTeam={detailsContacts}
            />
          </Wrapper>
          <Wrapper>
            <ClientOnly>
              <ScrollOverlay id="gotowe-etapy" anchorText="gotowe-etapy" />
            </ClientOnly>
            <ConstructionProgressGallery
              heading={HOMEPAGE.homepageFinishedStagesTitle}
              slides={progressGallery}
            />
          </Wrapper>
          <Wrapper>
            <ClientOnly>
              <ScrollOverlay id="galeria" anchorText="galeria" />
            </ClientOnly>
            <Gallery
              heading="GALERIA"
              image={
                HOMEPAGE.homepageGalleryImg.localFile.childImageSharp.fluid
              }
              albums={galleryAlbums}
            />
          </Wrapper>
          <Wrapper>
            <ClientOnly>
              <ScrollOverlay id="kontakt" anchorText="kontakt" />
            </ClientOnly>
            <Contact
              title="Sprzedaż mieszkań:"
              address="ul. Limanowskiego 5"
              mapCords={{
                lat: Number(HOMEPAGE.homepageContactLat),
                lng: Number(HOMEPAGE.homepageContactLng),
              }}
              openingHours={openingHours}
              phone={HOMEPAGE.homepageContactPhoneNumber}
              announcement={HOMEPAGE.homepageContactCommunication}
              pdf={{
                text: HOMEPAGE.homepagePromotionButtonText,
                link:
                  HOMEPAGE.homepagePromotionFile &&
                  HOMEPAGE.homepagePromotionFile.link,
              }}
            />
          </Wrapper>
        </main>
        <LazyLoad height={150} offset={500} once>
          <Footer />
        </LazyLoad>
        {/* <Cookies /> */}
      </>
    )
  );
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryHomePage {
    wpPage {
      homepage {
        homepageHeroPopup {
          sourceUrl
        }
        homepageHeroText
        homepageHeroDescription
        homepageHeroIlu {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        homepageHeroImg {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        homepageAboutInvestmentDescription
        homepageAboutInvestmentGraphic {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        homepageAboutInvestmentTimeline {
          homepageAboutInvestmentTimelineTitle
          homepageAboutInvestmentTimelineDate
          homepageAboutInvestmentTimelineActive
        }
        homepageAboutInvestmentProspect {
          homepageAboutInvestmentProspectTitle
          homepageAboutInvestmentProspectButtonText
          homepageAboutInvestmentProspectFile {
            link
          }
        }
        homepageLocationDescription
        homepageLocationIlu {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        homepageLocationMap {
          homepageLocationMapLat
          homepageLocationMapLng
          homepageLocationMapPlace
        }
        homepageLocationPlaces {
          homepageLocationPlacesText
          homepageLocationPlacesImg {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        homepage3dSearchEngineVisibility
        homepage3dSearchEngineText
        homepage3dSearchEngineIlu {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        homepage3dSearchEngineWiz {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        homepageCreditsTitle
        homepageCreditsDescription
        homepageCreditsContactDescription
        homepageCreditsAdvisors {
          homepageCreditsAdvisorsEmail
          homepageCreditsAdvisorsName
          homepageCreditsAdvisorsPhoneNumber
          homepageCreditsAdvisorsPostion
          plec
        }
        homepageCreditsText
        homepageCreditsAddendum
        homepageCreditsOpeningHours {
          homepageCreditsOpeningHoursClosed
          homepageCreditsOpeningHoursDay
          homepageCreditsOpeningHoursOpened
        }
        homepageFinishingTitle
        homepageFinishingDescription
        homepageFinishingDeerDesign {
          homepageFinishingDeerDesignPhoneNumber
          homepageFinishingDeerDesignText
          homepageFinishingDeerDesignText2
          homepageFinishingDeerDesignPhoneNumber
          homepageFinishingDeerDesignLogo {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          homepageFinishingDeerDesignDescription
          homepageFinishingDeerDesignImg {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          homepageFinishingDeerDesignPackage {
            homepageFinishingDeerDesignPackageName
            homepageFinishingDeerDesignPackageText
            homepageFinishingDeerDesignPackageFile {
              link
            }
          }
          homepageFinishingDeerDesignRules {
            link
          }
        }
        homepageFinishingExcellent {
          homepageFinishingExcellentText
          homepageFinishingExcellentPhoneNumber
          homepageFinishingExcellentName
          homepageFinishingExcellentEMail
          homepageFinishingExcellentDescription
          homepageFinishingExcellentFile {
            link
          }
          homepageFinishingExcellentImg {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        homepageFinishedStagesTitle
        homepageFinishedStagesGallery {
          homepageFinishedStagesGalleryImg {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        homepageGalleryImg {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        homepageGalleryCarousel {
          homepageGalleryCarouselTitle
          homepageGalleryCarouselImgs {
            homepageGalleryCarouselImgsSingle {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        homepageContactLat
        homepageContactLng
        homepageContactCommunication
        homepageContactPhoneNumber
        homepageContactOpeningHours {
          homepageContactOpeningHoursClosed
          homepageContactOpeningHoursDay
          homepageContactOpeningHoursOpened
        }
        homepagePromotionButtonText
        homepagePromotionFile {
          link
        }
      }
    }
  }
`;

export default IndexPage;
